
import QuotationsList from "@/modules/quotation/components/QuotationsList.vue";
import { defineComponent, onMounted } from "vue";
import {setPageHeader} from "@/core/helpers/toolbar";

export default defineComponent({
    components: {
        QuotationsList
    },
    setup() {
        onMounted(() => {
            setPageHeader({
                title: "message.QUOTATIONS",
                actionButton : null,
                breadCrumbs: [
                    { name: "message.HOME", link: "/dashboard"},
                    { name: "message.QUOTATIONS", link: "#"},
                    { name: "message.QUOTATIONS"}
                ]
            })
        });
    }
});
