
import {defineComponent, onMounted, ref, watchEffect} from 'vue';
import { Apollo } from '@/core/services';
import { useStore } from 'vuex';
import { GET_QUOTATION_LISTS, SEARCH_QUOTATION_LISTS } from '../graphql/Queries';
import Table from '../../../components/Table/Table.vue';
import { useRouter } from 'vue-router';
import { setPageHistory } from '@/core/helpers/toolbar';
import InnerLoader from '../../../components/InnerLoader.vue';
import AdvanceSearch from '../../../components/search/AdvanceSearch.vue';
import { ContentLoader } from 'vue-content-loader';
import {SEARCH_ORDER_LISTS} from "@/modules/order/graphql/Queries";
import {useI18n} from "vue-i18n";

export default defineComponent({
    name: 'Quotation',
    components: {
        Table,
        // InnerLoader,
        AdvanceSearch
        // ContentLoader
    },
    setup() {
        const store = useStore();
        const i18n = useI18n();
        const loader = ref(false);
        const loading = ref(false);
        const pagination = ref({}) as Record<any, any>;
        const quotations: any = ref([]);
        const dashboard = ref({}) as Record<any, any>;
        const system_locale = ref();
        const clickHandler = ref() as Record<any, any>;
        const currentPage = ref();
        const showSearchBar = ref(false);
        const router = useRouter();
        const permissions = store.getters.getPermissions;
        const activeIndex = ref(-1);
        const columnName = ref(null);
        const columnSort = ref(null);
        const total_revenue = ref(null);
        const filterOption = ref({}) as Record<any, any>;
        const search_quotation_query = ref(false);
        const showDelete = ref(false);

        filterOption.value = {
            date_from: true,
            date_to: true,
            name_order: true,
            order_id: true,
            date_filter: true
        };

        const columns = ref([
            {
                label: 'message.QUOTATION_ID',
                key: 'id'
            },
            {
                label: 'message.CUSTOMER',
                key: 'name'
            },
            {
                label: 'message.QUOTE_SIGNED',
                key: 'quotation_signed_status'
            },
            {
                label: 'message.QUOTE_ORDERED',
                key: 'is_ordered'
            },
            {
                label: 'message.TOTAL',
                key: 'total',
                textAlignment: 'end'
            },
            {
                label: 'message.CREATED_AT',
                key: 'created_at'
            },
            {
                label: 'message.ACTIONS',
                key: 'actions',
                textAlignment: 'center'
            }
        ]);

        watchEffect(() => {
            system_locale.value = i18n.fallbackLocale.value;
        });

        const dropdownHandler = (index, id) => {
            activeIndex.value = index === id ? null : id;
        };
        const handleClickOutside = event => {
            if (!event.target.closest('.action-btn')) {
                activeIndex.value = -1;
            }
        };

        const Quotations = (page = 0, reload = false) => {
            loader.value = true;
            columnName.value = null;
            columnSort.value = null;
            search_quotation_query.value = false;
            Apollo.watchQuery({
                query: GET_QUOTATION_LISTS,
                errorPolicy: 'all',
                fetchPolicy: 'network-only',
                nextFetchPolicy: 'cache-only',
                variables: {
                    page: page,
                    limit: 20
                }
            }).subscribe(({ data, errors }) => {
                if (errors) {
                    loader.value = false;
                }
                reload == true ? (currentPage.value = 1) : false;
                clickHandler.value = Quotations;
                quotations.value = data.quotations.data;
                pagination.value = data.quotations;
                loader.value = false;
            });
        };

        const searchHandler = (page = 0, reload = false) => {
            const searchData = store.getters?.getSearchData;
            loader.value = true;
            Apollo.watchQuery({
                query: SEARCH_QUOTATION_LISTS,
                errorPolicy: 'all',
                fetchPolicy: 'network-only',
                nextFetchPolicy: 'cache-only',
                variables: {
                    page: page,
                    limit: 20,
                    filter: JSON.stringify(searchData?.data?.select)
                }
            }).subscribe(({ data, errors }) => {
                if (errors) {
                    loader.value = false;
                }
                clickHandler.value = searchHandler;
                quotations.value = [];
                quotations.value = data.search_quotations.data;
                pagination.value = data.search_quotations;
                loader.value = false;
            });
        };

        const handleView = (quotation: Record<any, any>) => {
            const searchData = store.getters.getSearchData;
            setPageHistory({
                filter: searchData?.data?.select,
                search_key: searchData?.data?.input,
                page_number: pagination.value?.current_page
            });
            router.push({
                name: 'quotation_details',
                params: { uuid: quotation.uuid }
            });
        };

        const exportFile = (data) => {
            const searchData = store.getters.getSearchData;
            loading.value = true;
            const sub = Apollo.watchQuery({
                query: SEARCH_QUOTATION_LISTS,
                errorPolicy: 'all',
                fetchPolicy: 'network-only',
                variables: {
                    filter: JSON.stringify(searchData?.data?.select ? searchData?.data?.select : ''),
                    locale: system_locale.value,
                    types: data?.type,
                    export_date_from: data?.dateFrom,
                    export_date_to: data?.dateTo
                }
            }).subscribe(({ data, errors }) => {
                if (errors) {
                    loading.value = false;
                } else if (data) {
                    window.open(data?.search_orders?.data[0]?.path, '_blank');
                    loading.value = false;
                }
                loading.value = false;
                sub.unsubscribe();
            });
        };

        const getQuotations = (page, reload) => {
            Quotations(page, reload);
        };

        onMounted(() => {
            const pageHistory = store.getters.getPageHistory;
            document.addEventListener('click', handleClickOutside);
            pageHistory.page_number == undefined ? getQuotations(0, true) : getQuotations(pageHistory.page_number, false);
        });

        const handleCurrentChange = page_number => {
            clickHandler.value(page_number);
        };

        return {
            quotations,
            pagination,
            loading,
            dashboard,
            clickHandler,
            currentPage,
            showSearchBar,
            system_locale,
            columns,
            permissions,
            loader,
            searchHandler,
            handleView,
            handleCurrentChange,
            getQuotations,
            activeIndex,
            dropdownHandler,
            handleClickOutside,
            columnName,
            columnSort,
            filterOption,
            showDelete,
            total_revenue,
            exportFile
        };
    }
});
